import React from "react";

export function Logo() {
  return (
    <div
      style={{ fontFamily: "GapyeongHanseokbong" }}
      className="text-xl font-bold ml-1 text-stone-600"
    >
      <a href="https://us-mal.com">미국말싸미</a>
    </div>
  );
}
