import React, { useEffect, useState } from "react";
import { EnhancedText } from "./EnhancedText";

const naturalEnglishResponses = [
  "이미 충분히 자연스러워요.",
  "수정할 필요가 없어요.",
  "수정할 부분이 없어요.",
  "이 글은 자연스러워요.",
];

function useText() {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(
      naturalEnglishResponses[
        Math.floor(Math.random() * naturalEnglishResponses.length)
      ]
    );
  }, []);
  return text;
}

function useEmoji() {
  const [emoji, setEmoji] = useState("");

  useEffect(() => {
    setEmoji(positiveEmojis[Math.floor(Math.random() * positiveEmojis.length)]);
  }, []);
  return emoji;
}

const positiveEmojis = ["👍", "👏", "💯", "💪", "🤩"];

export function AlreadyNatural() {
  const emoji = useEmoji();
  const text = useText();
  return <EnhancedText text={`${emoji} ${text}`} language="미국말" />;
}
