import React, { useRef } from "react";

import koreaFlag from "../assets/ko.png";
import usaFlag from "../assets/us.png";
import robot from "../assets/bot.png";
import { FadeIn } from "./FadeIn";

export function EnhancedText({
  text,
  flag,
  language,
}: {
  text: string;
  flag?: string;
  language: string;
}) {
  return (
    <>
      <FadeIn>
        <div
          className={
            "text-xs text-stone-600 ml-1 flex items-center space-x-1 relative top-1 mb-1"
          }
        >
          {flag == null ? (
            <img
              src={
                language === "한국말"
                  ? koreaFlag
                  : language === "미국말"
                  ? usaFlag
                  : robot
              }
              className="w-6 h-6"
            />
          ) : (
            <span>{flag}</span>
          )}
          <span
            style={{ fontFamily: "GapyeongHanseokbong" }}
            className="font-bold"
          >
            {language}
          </span>
        </div>
      </FadeIn>
      <FadeIn delay={0.025}>
        <div
          className={
            "font-serif select-all text-sm text-stone-600 bg-white rounded-md shadow-sm p-4 whitespace-pre-wrap"
          }
        >
          {text}
        </div>
      </FadeIn>
    </>
  );
}
