import React, { useEffect, useRef, useState } from "react";
import logoImage from "./assets/logo.svg";
import { Correction } from "./components/Correction";
import { EnhancedText } from "./components/EnhancedText";
import { CopyButton } from "./components/CopyButton";
import { Logo } from "./components/Logo";
import clsx from "clsx";
import { AlreadyNatural } from "./components/AlreadyNatural";
import { Unprocessable } from "./components/Unprocessable";
import { FadeIn } from "./components/FadeIn";
import {
  CorrectlyAgentTranslateResponse,
  translate,
} from "./services/agent/translate";
import { explain, SegmentExplanation } from "./services/agent/explain";

declare function gtag_report_conversion(): void;

export function App() {
  const [text, setText] = useState("");
  const previousText = useRef("");
  const [enhancedText, setEnhancedText] =
    useState<CorrectlyAgentTranslateResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [explanations, setExplanations] = useState<SegmentExplanation[]>([]);
  // Define the gtag_report_conversion function

  async function triggerEnhance() {
    (window as any).gtag_report_conversion();
    setIsLoading(true);
    setEnhancedText(null);
    setExplanations([]);
    try {
      const enhancedResponse = await translate(text);
      previousText.current = text;
      setEnhancedText(enhancedResponse);
      setIsLoading(false);

      const changes = await explain({
        original_text: text,
        refined_text: enhancedResponse,
      });
      setExplanations(changes);

      console.log(changes);
    } finally {
      setIsLoading(false);
    }
  }

  const disabled =
    text.trim().length === 0 || isLoading || previousText.current === text;
  const loadingText = useLoadingButtonText(isLoading);
  const placeholderText = usePlaceholderText(text);

  const result =
    enhancedText == null || enhancedText.length === 0 ? null : enhancedText ===
      "INVALID_INPUT" ? (
      <Unprocessable />
    ) : enhancedText === "ALREADY_NATURAL" ? (
      <AlreadyNatural />
    ) : (
      <>
        <EnhancedText text={enhancedText} flag="🇺🇸" language="미국말" />
        <FadeIn delay={0.1}>
          <CopyButton text={enhancedText} />
        </FadeIn>

        {/* <EnhancedText
          text={enhancement.enhanced_text_in_korean}
          flag="🇰🇷"
          language="한국말"
        /> */}

        <div className="flex flex-col space-y-4 pt-2 pb-2">
          {explanations.map((segmentExplanation, index) => (
            <Correction
              key={index}
              index={index}
              segmentExplanation={segmentExplanation}
            />
          ))}
        </div>
      </>
    );

  return (
    <div className="flex flex-col space-y-2 mx-auto py-4 max-w-md w-full px-4">
      <Logo />

      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            e.stopPropagation();

            if (!disabled && !e.nativeEvent.isComposing) {
              triggerEnhance();
            }
          }
        }}
        className={clsx(
          "font-serif font-normal text-base p-2 focus:outline-none border border-gray-200 rounded-md shadow-inner text-stone-600"
        )}
        rows={3}
        placeholder={`e.g. ${placeholderText}`}
        spellCheck={false}
        autoCapitalize="none"
        autoCorrect="off"
        autoFocus={true}
        autoComplete="off"
      />

      <button
        disabled={disabled}
        onClick={triggerEnhance}
        className={clsx(
          "font-serif text-sm px-4 py-2 rounded-md transition-colors duration-150",
          disabled
            ? "bg-stone-100 text-stone-400 cursor-not-allowed"
            : "bg-stone-200 text-stone-600 hover:bg-stone-300",
          isLoading && "cursor-wait animate-pulse"
        )}
      >
        {loadingText}
      </button>

      {result}
    </div>
  );
}

function useLoadingButtonText(isLoading: boolean) {
  const [dots, setDots] = useState("");

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? "" : prevDots + "."));
      }, 300);
      return () => clearInterval(interval);
    }
  }, [isLoading]);

  return isLoading ? (
    <span>
      <span className="invisible">{dots}</span>미국말로 바꾸는 중{dots}
    </span>
  ) : (
    <span>미국말로 바꾸기</span>
  );
}

// Konglish and Korean false friends examples.
const EXAMPLE_KONGLISH_TEXTS = [
  "before we go drive I have to 점검 my blackbox and back mirror",
  "doctor from 치과 said my love tooth is big",
  "수능 is tomorrow, Fighting!",
];

function usePlaceholderText(text: string) {
  // when text is empty, return random example text
  // when text is not empty, and then empty again, return another random example text
  const [exampleIndex, setExampleIndex] = useState(
    Math.floor(Math.random() * EXAMPLE_KONGLISH_TEXTS.length)
  );

  const [lastText, setLastText] = useState("");

  useEffect(() => {
    if (text.trim().length === 0 && lastText.trim().length !== 0) {
      setExampleIndex(
        (prevIndex) => (prevIndex + 1) % EXAMPLE_KONGLISH_TEXTS.length
      );
    }

    setLastText(text);
  }, [text]);

  return EXAMPLE_KONGLISH_TEXTS[exampleIndex];
}
