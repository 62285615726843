import React, { useRef } from "react";
import { ChangedPart } from "../services/enhanceText";
import { SegmentExplanation } from "../services/v3/explain";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

export function Correction({
  index,
  segmentExplanation,
}: {
  index: number;
  segmentExplanation: SegmentExplanation;
}) {
  const ref = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.from(ref.current, {
      opacity: 0,
      y: index * 2 + 2,
      duration: 0.8,
      ease: "back.out",
      delay: index * 0.3,
    });
  });

  if (
    segmentExplanation.original_segment === segmentExplanation.refined_segment
  ) {
    return null;
  }

  const isOriginalEmpty = segmentExplanation.original_segment.length === 0;
  const isRefinedEmpty = segmentExplanation.refined_segment.length === 0;

  if (isOriginalEmpty && isRefinedEmpty) {
    return null;
  }

  const showArrow = !isOriginalEmpty && !isRefinedEmpty;

  return (
    <div ref={ref} className="flex flex-col space-y-1 px-2">
      <div className="flex items-center justify-between">
        <div className="text-base font-serif space-x-1">
          <span className="text-stone-400 font-small">
            {segmentExplanation.original_segment}
          </span>
          {showArrow && <ArrowIcon />}
          <span className="text-stone-600 font-semibold">
            {segmentExplanation.refined_segment}
          </span>
        </div>
      </div>
      <span className="text-sm text-stone-600 whitespace-pre-wrap">
        {segmentExplanation.explanation_of_this_change}
      </span>
    </div>
  );
}

const ArrowIcon = () => (
  <svg
    className="inline"
    xmlns="http://www.w3.org/2000/svg"
    style={{ opacity: 0.8 }}
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#888"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="5" y1="12" x2="19" y2="12"></line>
    <polyline points="12 5 19 12 12 19"></polyline>
  </svg>
);
