import { CorrectlyAgentTranslateResponse } from "./translate";

const API_ENDPOINT = "https://correctly-agent.doge-caprover.com/explain";

export type SegmentExplanation = {
  original_segment: string;
  refined_segment: CorrectlyAgentTranslateResponse;
  explanation_of_this_change: string;
};

export async function explain({
  original_text,
  refined_text,
}: {
  original_text: string;
  refined_text: CorrectlyAgentTranslateResponse;
}) {
  //   if (process.env.NODE_ENV === "development") {
  //     await new Promise((resolve) => setTimeout(resolve, 1500));
  //     return dummyExplanations;
  //   }

  if (
    original_text.length === 0 ||
    refined_text.length === 0 ||
    original_text === refined_text ||
    refined_text === "ALREADY_NATURAL" ||
    refined_text === "INVALID_INPUT"
  ) {
    return [];
  }

  return fetch(API_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({ original_text, refined_text }),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) {
        console.log("res", res);
        throw new Error("Network response was not ok");
      }
      return res.json();
    })
    .then((data) => data.explanation.changes as SegmentExplanation[]);
}

const dummyExplanations: SegmentExplanation[] = [
  {
    refined_segment: "Hello,",
    original_segment: "Hello",
    explanation_of_this_change:
      "문장 시작 부분인 'Hello' 뒤에 쉼표를 추가했어요. 영어에서는 인사말 뒤에 쉼표를 넣는 것이 일반적이에요. 이렇게 하면 문장이 더 자연스럽고 읽기 쉬워져요.",
  },
  {
    refined_segment: "my name is Minhwan.",
    original_segment: "my name is minhwan.",
    explanation_of_this_change:
      "'minhwan'의 첫 글자를 대문자인 'Minhwan'으로 수정했어요. 영어에서는 사람 이름의 첫 글자는 항상 대문자로 시작해야 해요. 이 수정으로 인해 문장의 형식이 더 정확해지고, 이름이 더 눈에 띄게 보여요.",
  },
  {
    refined_segment: "It's nice to meet you!",
    original_segment: "i'm nice to meet you!",
    explanation_of_this_change:
      "'i'm'을 'It's'로 바꿨어요. 'I'm'은 'I am'의 줄임말로, 주어가 나일 때 쓰지만, 여기서는 'It is nice to meet you'라는 표현이 더 자연스러워요. 이렇게 바꾸면 처음 만나는 사람에게 인사를 할 때 더 적절한 표현이 되어요.",
  },
];
