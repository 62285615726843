import "./style.css";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import React from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const container = document.getElementById("app");
const root = createRoot(container);

gsap.registerPlugin(useGSAP);
root.render(<App />);
