const API_ENDPOINT = "https://correctly-agent.doge-caprover.com/translate";

export type CorrectlyAgentTranslateResponse =
  | "INVALID_INPUT"
  | "ALREADY_NATURAL"
  | (string & {});

export async function translate(input_text: string) {
  //   if (process.env.NODE_ENV === "development") {
  //     await new Promise((resolve) => setTimeout(resolve, 400));
  //     return "Good";
  //   }

  return fetch(API_ENDPOINT, {
    method: "POST",
    body: JSON.stringify({ input_text }),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) {
        console.log("res", res);
        throw new Error("Network response was not ok");
      }
      return res.json();
    })
    .then((data) => data.output_text as CorrectlyAgentTranslateResponse);
}
