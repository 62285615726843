import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import React, { useRef } from "react";

export function FadeIn({
  children,
  delay = 0,
  duration = 0.6,
}: {
  children: React.ReactNode;
  index?: number;
  delay?: number;
  duration?: number;
}) {
  const ref = useRef<HTMLDivElement>(null);
  useGSAP(() => {
    gsap.from(ref.current, {
      opacity: 0,
      y: 2,
      duration: duration,
      ease: "back.out",
      delay: delay,
    });
  });
  return (
    <div ref={ref} className="w-full">
      {children}
    </div>
  );
}
