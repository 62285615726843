import clsx from "clsx";
import React, { useState } from "react";

export function CopyButton({ text }: { text: string }) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <button
      onClick={handleCopy}
      className={clsx(
        "w-full font-serif text-sm px-4 py-2 rounded-md transition-colors duration-300",
        isCopied
          ? "bg-green-100 text-green-800 cursor-not-allowed"
          : "bg-stone-200 text-stone-600 hover:bg-stone-300"
      )}
    >
      {isCopied ? "복사했어요" : "복사하기"}
    </button>
  );
}
