import React, { useEffect, useState } from "react";
import { EnhancedText } from "./EnhancedText";

const unprocessableResponses = [
  "잘 이해하지 못했어요.\n무슨 뜻인지 알려줄 수 있을까요?",
  "입력하신 내용을 처리할 수 없어요.\n의미 있는 단어로 다시 시도해주실 수 있을까요?",
  "입력 내용이 너무 특이해서 처리하기 어려워요.\n좀 더 일반적인 표현으로 바꿔주실 수 있나요?",
];

function useText() {
  const [text, setText] = useState("");

  useEffect(() => {
    setText(
      unprocessableResponses[
        Math.floor(Math.random() * unprocessableResponses.length)
      ]
    );
  }, []);
  return text;
}

export function Unprocessable() {
  const text = useText();
  return <EnhancedText text={text} flag="🤖" language="#@?!%" />;
}
